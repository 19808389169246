document.addEventListener("DOMContentLoaded", function () {
    const maintenance = document.querySelector('.maintenance');
    const dismissedNotificationsCookie = 'd_maintenance';

    if (maintenance) {
        if (checkNotification()) {
            parseMarkdown(maintenance.querySelector('.maintenance__text'));
            maintenance.classList.remove('display-none');

            const maintenanceClose = maintenance.querySelector('.maintenance__close');
            maintenanceClose.addEventListener("click", function () {
                closeNotification();
            });
        }
        else {
            maintenance.remove();
        }
    }

    function closeNotification() {
        const hash = maintenance.getAttribute('data-id');
        addToCookie(hash);

        maintenance.classList.add('display-none');
    }

    function checkNotification() {
        const hash = maintenance.getAttribute('data-id');

        return checkCookie(hash);
    }

    function parseMarkdown(element) {
        if (element) {
            const value = element.innerText;
            element.innerHTML = DOMPurify.sanitize(marked.parse(value));
        }
    }

    function checkCookie(hash) {
        let cookie = getCookie(dismissedNotificationsCookie);
        let items = [];

        if (cookie != '') {
            items = JSON.parse(cookie);
        }

        return !items.includes(hash);
    }

    function addToCookie(hash) {
        let cookie = getCookie(dismissedNotificationsCookie);
        let items = [];

        if (cookie != '') {
            items = JSON.parse(cookie);
        }
        items.push(hash);

        setCookie(dismissedNotificationsCookie, JSON.stringify(items), 15);
    }
});



